import { FC } from 'react';
import ContentCard from '../../../../../components/ContentCard';
import { MachineHistory } from '../../../../../types/serverInterface/machineDTO';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './MachineHomePageHistory.module.scss';
import { Roles } from '../../../../../types/serverInterface/cabinetDTO';
import { Avatar } from '@consta/uikit/Avatar';

const mockHistories: MachineHistory[] = [
  {
    name: 'Иван',
    surname: 'Петров',
    patronymic: 'Иванович',
    position: Roles.TECHNICIAN,
    date: '09.04.2024 11:46',
  },
  {
    name: 'Мария',
    surname: 'Сидорова',
    patronymic: 'Павловна',
    position: Roles.TECHNICIAN,
    date: '12.05.2023 09:22',
  },
  {
    name: 'Алексей',
    surname: 'Иванов',
    patronymic: 'Сергеевич',
    position: Roles.TECHNICIAN,
    date: '18.10.2024 15:30',
  },
  {
    name: 'Елена',
    surname: 'Козлова',
    patronymic: 'Дмитриевна',
    position: Roles.TECHNICIAN,
    date: '21.07.2023 13:15',
  },
  {
    name: 'Денис',
    surname: 'Смирнов',
    patronymic: 'Александрович',
    position: Roles.TECHNICIAN,
    date: '02.12.2024 08:50',
  },
  {
    name: 'Ольга',
    surname: 'Попова',
    patronymic: 'Андреевна',
    position: Roles.TECHNICIAN,
    date: '29.03.2023 10:05',
  },
  {
    name: 'Андрей',
    surname: 'Морозов',
    patronymic: 'Николаевич',
    position: Roles.TECHNICIAN,
    date: '17.09.2024 16:45',
  },
  {
    name: 'Татьяна',
    surname: 'Зайцева',
    patronymic: 'Игоревна',
    position: Roles.TECHNICIAN,
    date: '05.06.2023 14:20',
  },
  {
    name: 'Сергей',
    surname: 'Кузнецов',
    patronymic: 'Владимирович',
    position: Roles.TECHNICIAN,
    date: '08.11.2024 12:35',
  },
  {
    name: 'Анна',
    surname: 'Борисова',
    patronymic: 'Евгеньевна',
    position: Roles.TECHNICIAN,
    date: '23.08.2023 09:55',
  },
  {
    name: 'Павел',
    surname: 'Лебедев',
    patronymic: 'Максимович',
    position: Roles.TECHNICIAN,
    date: '14.04.2024 17:10',
  },
  {
    name: 'Екатерина',
    surname: 'Ковалева',
    patronymic: 'Ильинична',
    position: Roles.TECHNICIAN,
    date: '30.01.2023 11:55',
  },
  {
    name: 'Максим',
    surname: 'Соловьев',
    patronymic: 'Семенович',
    position: Roles.TECHNICIAN,
    date: '03.07.2024 10:40',
  },
  {
    name: 'Наталья',
    surname: 'Медведева',
    patronymic: 'Алексеевна',
    position: Roles.TECHNICIAN,
    date: '11.11.2023 13:30',
  },
  {
    name: 'Дмитрий',
    surname: 'Яковлев',
    patronymic: 'Денисович',
    position: Roles.TECHNICIAN,
    date: '26.05.2024 14:50',
  },
];

/**
 * Свойства компонента MachineHomaPageHistory
 */
type MachineHomePageHistoryProps = {
  /**
   * История активности автомата
   */
  histories: MachineHistory[];
};

/**
 * История активности автомата
 */
const MachineHomePageHistory: FC<MachineHomePageHistoryProps> = ({ histories = mockHistories }) => {
  // Вспомогательные методы
  const getRoleLabel = (role: Roles) => {
    switch (role) {
      case Roles.ROOT:
        return 'root';
      case Roles.DEVELOPER:
        return 'Разработчик';
      case Roles.MANUFACTURER:
        return 'Производитель';
      case Roles.ORGANIZATION_OWNER:
        return 'Владелец организации';
      case Roles.ORGANIZATION_MEMBER:
        return 'Работник организации';
      case Roles.TECHNICIAN:
        return 'Техник';
      default:
        return 'Нет должности';
    }
  };

  // render методы
  const renderLog = (historyItem: MachineHistory, index: number) => {
    const { date: historyDate, position, surname, name } = historyItem;
    const [date, time] = historyDate.split(' ');
    const userFullName = `${name ? name : ''} ${surname ? surname : ''}`;
    return (
      <HorizontalContainer className={styles.historyCard} align="center" key={index}>
        <HorizontalContainer space="s" align="center">
          <Avatar size="l" className={styles.avatar} name={userFullName} />
          <VerticalContainer space="3xs">
            <Text size="m" weight="medium">
              {userFullName}
            </Text>
            <Text size="m" view="secondary">
              {getRoleLabel(position)}
            </Text>
          </VerticalContainer>
        </HorizontalContainer>
        <VerticalContainer align="end" space={0}>
          <Text size="s" view="secondary">
            {date}
          </Text>
          <Text size="s" view="secondary">
            {time}
          </Text>
        </VerticalContainer>
      </HorizontalContainer>
    );
  };

  return (
    <ContentCard className={styles.MachineHomePageHistory}>
      <Text size="l" weight="semibold">
        История активности
      </Text>
      <VerticalContainer space="s">
        {histories?.map((log, index) => renderLog(log, index))}
      </VerticalContainer>
    </ContentCard>
  );
};

export default MachineHomePageHistory;

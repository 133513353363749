import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import VendistaSettings from './VendistaSettings';

/**
 * Базовая страница vendista
 */
const VendistaPage: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<VendistaSettings />} />
    </Routes>
  );
};

export default VendistaPage;

import { RootState } from '../../app/store';

/**
 * Селектор получения личного кабинета пользователя
 */
export const selectCabinet = () => (state: RootState) => state.user.cabinet;

/**
 * Селектор получения флага загрузки личного кабинета
 */
export const selectCabinetIsLoading = () => (state: RootState) => state.user.isLoading;

/**
 * Селектор получения документов пользователя
 */
export const selectUserDocuments = () => (state: RootState) => state.user.documentList;

/**
 * Селектор получения списка уведомлений
 */
export const selectNotificationsList = () => (state: RootState) => [
  ...state.user.notifications,
  ...state.productBase.notifications,
  ...state.machineControl.notifications,
  ...state.auth.notifications,
  ...state.organization.notifications,
  ...state.promoCode.notifications,
  ...state.notification.notifications,
];

/**
 * Получение роли пользователя
 */
export const selectRole = () => (state: RootState) => state.user.cabinet?.role;

import { FC } from 'react';
import { CreateTelegramNotificationSettingDTO } from '../../../../../types/serverInterface/notificationDTO';
import { useFormik } from 'formik';
import { createTelegramNotificationValidationScheme } from './createTelegramNotificationValidationScheme';
import { useAppDispatch } from '../../../../../app/hooks/store';
import {
  createTelegramNotificationSettingAction,
  getTelegramNotificationSettingsByOrganizationIdAction,
} from '../../../../../state/notification/action';
import { getOrganizationId } from '../../../../../helpers/getOrganizationId';
import DefaultModal from '../../../../../components/DefaultModal';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/__internal__/src/components/Button';

const initialFormState: CreateTelegramNotificationSettingDTO = {
  chatId: '',
  organizationId: 0,
  botToken: '',
};

type CreateTelegramNotificationSettingProps = {
  onClose: () => void;
};

const CreateTelegramNotificationSetting: FC<CreateTelegramNotificationSettingProps> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const organizationId = getOrganizationId();

  const formik = useFormik({
    validationSchema: createTelegramNotificationValidationScheme,
    initialValues: initialFormState,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  // Обработчики
  function handleSubmit(data: CreateTelegramNotificationSettingDTO) {
    organizationId &&
      dispatch(createTelegramNotificationSettingAction({ ...data, organizationId })).then(() => {
        dispatch(getTelegramNotificationSettingsByOrganizationIdAction(organizationId));
      });
  }

  // render методы
  const renderActions = () => (
    <>
      <Button view="clear" label="Отмена" onClick={onClose} />
      <Button label="Сохранить" onClick={() => formik.handleSubmit()} />
    </>
  );

  return (
    <DefaultModal
      modalTitle="Настройка телеграм уведомлений"
      onClose={onClose}
      renderActions={renderActions}
    >
      <TextField
        label="id чата"
        name="chatId"
        width="full"
        value={formik.values.chatId}
        status={formik.errors.chatId ? 'alert' : undefined}
        caption={formik.errors.chatId}
        onChange={({ e }) => formik.handleChange(e)}
      />
      <TextField
        label="Токен бота"
        name="botToken"
        width="full"
        value={formik.values.botToken}
        status={formik.errors.botToken ? 'alert' : undefined}
        caption={formik.errors.botToken}
        onChange={({ e }) => formik.handleChange(e)}
      />
    </DefaultModal>
  );
};

export default CreateTelegramNotificationSetting;

import { ProductGroup, ProductView } from './storageDTO';
import { ConnectionStatus, StatusColor } from './machineDTO';
import { SortDirection } from '../enums/sortDirection';

export type MachineStatus = {
  /**
   * Текстовое обозначение статуса
   */
  text: string;
  /**
   * Дата возникновения статуса 09.04.2024 11:46
   */
  date: string | null;
  /**
   * Цвет статуса
   */
  color: StatusColor;
};

/**
 * Модель для списка автоматов
 */
export type ModelByMachineListDTO = {
  /**
   * Название модели автомата
   */
  name: string;
  /**
   * id модели автомата
   */
  id: number;
};

/**
 * Базовый тип ячейки модели автомата
 */
export type MachineModelBaseCellByList = {
  /**
   * Номер ячейки
   */
  cellNumber: number;
};

/**
 * Ячейка продукта для списка автоматов
 */
export type ProductCellsByList = MachineModelBaseCellByList & {
  /**
   * Максимальный объём ячейки
   */
  maxVolume: number;
  /**
   * Минимальный объём ячейки для отправки оповещения
   */
  minVolume: number;
  /**
   * Текущий объём ячейки
   */
  volume: number;
  /**
   * Название ячейки
   */
  name: string;
  /**
   * Группа продуктов
   */
  group: ProductGroup;
  /**
   * Внешний вид продуктов
   */
  view: ProductView;
};

/**
 * Ячейка стакана для списка автоматов
 */
export type CupCellByList = MachineModelBaseCellByList & {
  /**
   * Максимальный объём ячейки
   */
  maxVolume: number;
  /**
   * Минимальный объём ячейки для отправки оповещения
   */
  minVolume: number;
  /**
   * Текущий объём ячейки
   */
  volume: number;
  /**
   * Флаг считать количество
   */
  isCount: boolean;
};

/**
 * Ячейка расходника для списка автоматов
 */
export type DisposableCellByList = MachineModelBaseCellByList & {
  /**
   * Минимальный объём ячейки для отправки оповещения
   */
  minVolume: number;
  /**
   * Текущий объём ячейки
   */
  volume: number;
  /**
   * Название расходника
   */
  name: string;
  /**
   * Флаг считать количество
   */
  isCount: boolean;
};

/**
 * Ячейка воды для списка автоматов
 */
export type WaterCellByList = MachineModelBaseCellByList & {
  /**
   * Максимальный объём ячейки
   */
  maxVolume: number;
  /**
   * Минимальный объём ячейки для отправки оповещения
   */
  minVolume: number;
  /**
   * Текущий объём ячейки
   */
  volume: number;
  /**
   * Активная ячейка
   */
  isActive: boolean;
  /**
   * Флаг считать количество
   */
  isCount: boolean;
  /**
   * Таймер годности фильтра
   */
  expirationTimer?: number;
  /**
   * Объём годности фильтра
   */
  filterValue?: number;
};

/**
 * Ячейки автомата
 */
export type MachineCellsDTO = {
  /**
   * Ячейки продуктов
   */
  cells: ProductCellsByList[] | null;
  /**
   * Ячейки воды
   */
  cellWaters: WaterCellByList[] | null;
  /**
   * Ячейки стаканов
   */
  cellCups: CupCellByList[] | null;
  /**
   * Ячейки расходников
   */
  cellDisposables: DisposableCellByList[] | null;
};

/**
 * Ячейка продукта снек автомата
 */
export type MachineSnackCell = {
  /**
   * id ячейки
   */
  id: number;
  /**
   * Номер ряда
   */
  rowNumber: number;
  /**
   * Остаток в ячейке
   */
  volume: number;
  /**
   * Ёмкость ячейки
   */
  maxVolume: number;
  /**
   * Минимальный остаток в ячейке
   */
  minVolume: number;
  /**
   * Остаток, при котором жёлтый статус
   */
  warningVolume: number;
  /**
   * Заблокирована вручную
   */
  isManuallyDisabled: boolean;
  /**
   * Номера моторов, используемых в этой ячейке
   */
  motorIds: number[];
};

/**
 * Ячейки снек автомата
 */
export type MachineSnackCells = {
  /**
   * Ячейки продуктов снек автомата
   */
  snackCells: MachineSnackCell[] | null;
};

/**
 * Элемент списка автоматов
 */
export type MachineListItem = MachineSnackCells &
  MachineCellsDTO & {
    /**
     * id автомата
     */
    id: number;
    /**
     * Название автомата
     */
    name: string;
    /**
     * Серийный номер автомата
     */
    serialNumber: string;
    /**
     * id организации, в которой привязан автомат
     */
    organizationId: number | null;
    /**
     * id модели автомата
     */
    machineModelId: number | null;
    /**
     * Статус подключения автомата к телеметрии
     */
    connectionStatus: ConnectionStatus;
    /**
     * Статус автомата
     */
    status: MachineStatus;
    /**
     * Флаг нового автомата
     */
    isNew: true;
    /**
     * Модель автомата
     */
    model: ModelByMachineListDTO;
    /**
     * id торговой точки
     */
    outletId: number | null;
    /**
     * Продажи автомат
     */
    salePeriodResult: {
      /**
       * Количество продаж
       */
      qty: number;
    };
  };

/**
 * Информация для фильтра модели автомата
 */
export type MachineListFiltersModelInfo = {
  /**
   * id модели автомата
   */
  modelId: number;
  /**
   * Название модели автомата
   */
  modelName: string;
  /**
   * Количество автоматов в модели автомата
   */
  modelCount: number;
};

/**
 * Информация для фильтра статусов автомата
 */
export type MachineListFiltersStatusInfo = {
  error: number;
  warning: number;
  success: number;
};

/**
 * Возможные настройки отображения продаж автомата
 */
export enum SalesFilters {
  // календарный день(с 00 сегодня)
  Today = 'Today',
  // календарная неделя(с понедельника)
  SinceMonday = 'SinceMonday',
  // календарный месяц(с 1 числа текущего месяца)
  SinceFirstDayOfMonth = 'SinceFirstDayOfMonth',
  // последние 24 часа
  Last24Hours = 'Last24Hours',
  // последняя неделя
  Last7Days = 'Last7Days',
  // последний месяц
  Last30Days = 'Last30Days',
}

/**
 * Возможные фильтры причины отключения автомата
 */
export enum ShutdownReasonFilters {
  // На складе
  IN_STOCK = 'IN_STOCK',
  // На ремонте
  ON_REPAIR = 'ON_REPAIR',
  // Продано
  // SOLD = 'SOLD',
  // Утилизировано
  // SCRAP = 'SCRAP',
}

/**
 * Фильтры списка автоматов
 */
export type MachineListFilters = {
  /**
   * id организации
   */
  organizationId: number | null;
  /**
   * Поле поиска по названию автомата
   */
  name: string | null;
  /**
   * Цвет статуса автомата
   */
  statusColors: StatusColor[] | null;
  /**
   * Причину отключения автомата
   */
  shutdownReasonFilter: ShutdownReasonFilters | null;
  /**
   * Список id моделей автомата
   */
  machineModelIds: number[] | null;
  /**
   * Флаг архивности
   */
  isArchived: boolean | null;
  /**
   * Настройка отображения продаж
   */
  salesFilter: SalesFilters;
  /**
   * Направление сортировки по названию автомата
   */
  sortNameDirection: SortDirection | null;
  /**
   * Направление сортировки по статусу
   */
  sortStatusDirection: SortDirection | null;
  /**
   * Направление сортировки по воде
   */
  sortWaterDirection: SortDirection | null;
  /**
   * Направление сортировки по стаканам
   */
  sortCupsDirection: SortDirection | null;
};

/**
 * Информация о страницах списка автоматов
 */
export type MachineListFiltersPageInfo = {
  /**
   * Текущая страница
   */
  page: number;
  /**
   * Элементов на странице
   */
  limit: number;
  /**
   * Количество элементов на странице
   */
  items: number;
};

/**
 * Количество включённых/выключенный автоматов
 */
export type ArchivedCount = {
  /**
   * Количество выключенных автоматов
   */
  archived: number;
  /**
   * Количество включенных автоматов
   */
  active: number;
};

/**
 * Количество автоматов по причине отключения
 */
export type MachineAtShutdownCount = {
  /**
   * Количество автоматов на складе
   */
  in_STOCK: number;
  /**
   * Количество утилизированных автоматов
   */
  scrap: number;
  /**
   * Количество проданных автоматов
   */
  sold: number;
  /**
   * Количество автоматов находящихся в ремонте
   */
  on_REPAIR: number;
};

/**
 * Список автоматов
 */
export type MachineListDTO = MachineListFiltersPageInfo & {
  /**
   * Список автоматов
   */
  results: MachineListItem[];
  /**
   * Информация о статусах и количестве автоматов в этих статусах
   */
  machineAtStatusesCount: MachineListFiltersStatusInfo;
  /**
   * Информация о моделях и количестве автоматов в этих моделях
   */
  machineAtModel: MachineListFiltersModelInfo[];
  /**
   * Количество включённых/выключенный автоматов
   */
  archivedCount: ArchivedCount;
  /**
   * Количество автоматов по причине отключения
   */
  machineAtShutdownCount: MachineAtShutdownCount;
  /**
   * Параметры запроса
   */
  parameters: MachineListFilters;
};

/**
 * Фильтры запроса списка автоматов
 */
export type MachineFiltersDTO = MachineListFiltersPageInfo & MachineListFilters;

import { FC, useEffect, useState } from 'react';
import MachineRefillTable from './MachineRefillTable';
import {
  SeparatedMachineCellUnionType,
  SeparatedMachineStorageType,
} from '../../../../../components/Machine/MachineStorage/types';
import { ProductGroup } from '../../../../../types/serverInterface/storageDTO';
import MachineRefillScheme from './MachineRefillScheme';
import styles from './MachineRefill.module.scss';
import { DisplayType, displayTypeValue } from '../../../../App';
import classNames from 'classnames';
import VerticalContainer from '../../../../../components/VerticalContainer';
import {
  ConnectionStatus,
  MachineCellGroup,
  MachineCellGroupUnion,
  MachineStorageInfoProductCellFormatted,
  RefillMachineCell,
  RefillMachineStorageDTO,
} from '../../../../../types/serverInterface/machineDTO';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { MachineStoragePageType } from '../MachineFilling';
import {
  getMachineStorageInfoAction,
  refillMachineStorageAction,
} from '../../../../../state/machineControl/actions';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import { selectMachineBaseInfo } from '../../../../../state/machineControl/selectors';
import MachineOfflineBlockModal from '../../MachineOfflineBlockModal';
import { IconSave } from '../../../../../assets/icon/iconSave';

const getGroup = (
  group: MachineCellGroupUnion,
): keyof Omit<SeparatedMachineStorageType, 'cells'> => {
  switch (group) {
    case MachineCellGroup.WATER:
      return 'cellWaters';
    case MachineCellGroup.CUP:
      return 'cellCups';
    default:
      return 'cellDisposables';
  }
};

/**
 * Дефолтное значение склада автомата
 */
const initialStorage: SeparatedMachineStorageType = {
  cells: {
    [ProductGroup.COFFEE]: [],
    [ProductGroup.CONCENTRATE]: [],
    [ProductGroup.POWDER]: [],
  },
  cellWaters: [],
  cellCups: [],
  cellDisposables: [],
  snackCells: [],
};

/**
 * Свойства компонента MachineFillingInfo
 */
type MachineFillingInfoProps = {
  machineId: number;
  /**
   * Склад автомата
   */
  storage: SeparatedMachineStorageType;
  /**
   * Сортированный список групп продуктов склада автомата
   */
  sortedGroup: ProductGroup[];
  onPageTypeChange: (newPageType: MachineStoragePageType) => void;
  onIsShowTabsChange: (isShow: boolean) => void;
};

/**
 * Информация о наполнении
 */
const MachineRefill: FC<MachineFillingInfoProps> = ({
  machineId,
  storage,
  sortedGroup,
  onPageTypeChange,
  onIsShowTabsChange,
}) => {
  const dispatch = useAppDispatch();
  const { state: machineBaseInfo } = useAppSelector(selectMachineBaseInfo());

  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const [machineStorageArr, setMachineStorageArr] = useState({
    ...initialStorage,
  });
  const [isOpenOfflineBlockModal, setIsOpenOfflineBlockModal] = useState(false);

  useEffect(() => {
    setMachineStorageArr(storage);
  }, [storage]);

  // Обработчики
  const handleHover = (group: MachineCellGroupUnion) => (index: number) => {
    if (
      group === MachineCellGroup.CUP ||
      group == MachineCellGroup.DISPOSABLE ||
      group === MachineCellGroup.WATER
    ) {
      const formatGroup = getGroup(group);

      return setMachineStorageArr((prevState) => ({
        ...prevState,
        [formatGroup]: [
          ...prevState[formatGroup].slice(0, index),
          { ...prevState[formatGroup][index], isHoverStatus: true },
          ...prevState[formatGroup].slice(index + 1),
        ],
      }));
    }

    if (
      group === ProductGroup.COFFEE ||
      group == ProductGroup.CONCENTRATE ||
      group === ProductGroup.POWDER
    ) {
      setMachineStorageArr((prevState) => ({
        ...prevState,
        cells: {
          ...prevState.cells,
          [group]: [
            ...prevState.cells[group].slice(0, index),
            { ...prevState.cells[group][index], isHoverStatus: true },
            ...prevState.cells[group].slice(index + 1),
          ],
        },
      }));
    }
  };

  const handleHoverLeave = (group: MachineCellGroupUnion) => (index: number) => {
    if (
      group === MachineCellGroup.CUP ||
      group == MachineCellGroup.DISPOSABLE ||
      group === MachineCellGroup.WATER
    ) {
      const formatGroup = getGroup(group);

      return setMachineStorageArr((prevState) => ({
        ...prevState,
        [formatGroup]: [
          ...prevState[formatGroup].slice(0, index),
          { ...prevState[formatGroup][index], isHoverStatus: false },
          ...prevState[formatGroup].slice(index + 1),
        ],
      }));
    }

    if (
      group === ProductGroup.COFFEE ||
      group == ProductGroup.CONCENTRATE ||
      group === ProductGroup.POWDER
    ) {
      setMachineStorageArr((prevState) => ({
        ...prevState,
        cells: {
          ...prevState.cells,
          [group]: [
            ...prevState.cells[group].slice(0, index),
            { ...prevState.cells[group][index], isHoverStatus: false },
            ...prevState.cells[group].slice(index + 1),
          ],
        },
      }));
    }
  };

  const handleInputChange =
    (group: MachineCellGroupUnion) =>
    (index: number) =>
    (key: keyof MachineStorageInfoProductCellFormatted) =>
    (value: number | string | null) => {
      if (
        group === MachineCellGroup.CUP ||
        group === MachineCellGroup.WATER ||
        group === MachineCellGroup.DISPOSABLE
      ) {
        const formatGroup = getGroup(group);

        setMachineStorageArr((prevState) => ({
          ...prevState,
          [formatGroup]: [
            ...prevState[formatGroup].slice(0, index),
            {
              ...prevState[formatGroup][index],
              info: {
                ...prevState[formatGroup][index].info,
                [key]: value,
              },
            },
            ...prevState[formatGroup].slice(index + 1),
          ],
        }));
      }

      if (
        group === ProductGroup.COFFEE ||
        group == ProductGroup.CONCENTRATE ||
        group === ProductGroup.POWDER
      ) {
        setMachineStorageArr((prevState) => ({
          ...prevState,
          cells: {
            ...prevState.cells,
            [group]: [
              ...prevState.cells[group].slice(0, index),
              {
                ...prevState.cells[group][index],
                info: {
                  ...prevState.cells[group][index].info,
                  [key]: value,
                },
              },
              ...prevState.cells[group].slice(index + 1),
            ],
          },
        }));
      }
    };

  const handleValueChange =
    (group: MachineCellGroupUnion) => (index: number) => (value: number | string | null) => {
      let roundedValue: number | null = null;

      if (value !== null && value !== '') {
        const numericValue = typeof value === 'string' ? parseFloat(value) : value;
        roundedValue =
          group === MachineCellGroup.WATER
            ? Math.round(numericValue * 10) / 10
            : Math.round(numericValue);
      }
      handleInputChange(group)(index)('volume')(roundedValue);
    };

  const handleRefillSubmitClick = () => {
    if (machineBaseInfo && machineBaseInfo.connectionStatus === ConnectionStatus.OFFLINE) {
      setIsOpenOfflineBlockModal(true);
      return null;
    }

    const cells = {
      ...machineStorageArr.cells,
      [MachineCellGroup.WATER]: machineStorageArr.cellWaters.map((cell) => ({
        ...cell,
        info: { ...cell.info, volume: cell.info.volume * 1000 },
      })),
      [MachineCellGroup.CUP]: machineStorageArr.cellCups,
      [MachineCellGroup.DISPOSABLE]: machineStorageArr.cellDisposables,
    };

    const transformCellByRefill =
      (group: MachineCellGroupUnion) =>
      ({ info }: SeparatedMachineCellUnionType): RefillMachineCell => {
        return { id: info.id, volume: info.volume, group };
      };

    const combinedArr: RefillMachineStorageDTO = [
      ...cells[ProductGroup.COFFEE].map(transformCellByRefill(ProductGroup.COFFEE)),
      ...cells[ProductGroup.POWDER].map(transformCellByRefill(ProductGroup.POWDER)),
      ...cells[ProductGroup.CONCENTRATE].map(transformCellByRefill(ProductGroup.CONCENTRATE)),
      ...cells[MachineCellGroup.WATER].map(transformCellByRefill(MachineCellGroup.WATER)),
      ...cells[MachineCellGroup.CUP].map(transformCellByRefill(MachineCellGroup.CUP)),
      ...cells[MachineCellGroup.DISPOSABLE].map(transformCellByRefill(MachineCellGroup.DISPOSABLE)),
    ];

    dispatch(refillMachineStorageAction(combinedArr, machineId)).then(() => {
      dispatch(getMachineStorageInfoAction(machineId));
      onPageTypeChange(MachineStoragePageType.INFO);
      onIsShowTabsChange(true);
    });
  };

  const handleCloseOfflineBlockModal = () => {
    setIsOpenOfflineBlockModal(false);
  };

  // render методы
  const renderActions = () => (
    <div className={styles.actions}>
      <Button
        view="primary"
        size="m"
        onlyIcon
        iconLeft={IconSave as any}
        onClick={() => {
          handleRefillSubmitClick();
        }}
      />
    </div>
  );

  return (
    <div className={classNames(styles.MachineFillingInfo, isLaptop && styles.laptop)}>
      <MachineOfflineBlockModal
        isOpen={isOpenOfflineBlockModal}
        onCancel={handleCloseOfflineBlockModal}
      />
      <VerticalContainer className={styles.leftSide} space={isLaptop ? 's' : 'l'}>
        <MachineRefillTable
          storage={machineStorageArr}
          sortedGroup={sortedGroup}
          onHover={handleHover}
          onHoverLeave={handleHoverLeave}
          onInputChange={handleInputChange}
        />
      </VerticalContainer>
      <MachineRefillScheme
        className={styles.rightSide}
        storage={machineStorageArr}
        sortedGroup={sortedGroup}
        onHover={handleHover}
        onHoverLeave={handleHoverLeave}
        onValueChange={handleValueChange}
      />
      {renderActions()}
    </div>
  );
};

export default MachineRefill;

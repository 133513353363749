import axios from 'axios';
import { getDataFromServer } from '../../../../helpers/getDataFromServer';

export async function getVendistaToken(
  login: string,
  password: string,
): Promise<{ token: string } | null> {
  const url = `https://api.vendista.ru:99/token`;

  try {
    // TODO: вернуть после тестов
    // const response = await axios.get(url, {
    //   params: {
    //     login: login,
    //     password: password
    //   }
    // });
    //
    // return response.data.token;

    return getDataFromServer({ token: 'testToken' });
  } catch (error) {
    console.error('Ошибка при авторизации:', error);
    return null;
  }
}

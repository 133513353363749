import { FC, useState } from 'react';
import styles from './MachineDisconnect.module.scss';
import ContentCard from '../../../../../components/ContentCard';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import DisconnectModal from './DisconnectModal';
import { IconDisconnect } from '../../../../../assets/icon/iconDisconnect';

/**
 * Свойства компонента MachineDisconnect
 */
type MachineDisconnectProps = {
  /**
   * id автомата
   */
  machineId: number;
};

/**
 * Карточка отключения автомата от телеметрии
 */
const MachineDisconnect: FC<MachineDisconnectProps> = ({ machineId }) => {
  const [isOpenDisconnectModal, setIsOpenDisconnectModal] = useState(false);

  // Обработчики
  const handleDisconnectModalOpen = () => {
    setIsOpenDisconnectModal(true);
  };

  const handleDisconnectModalClose = () => {
    setIsOpenDisconnectModal(false);
  };

  return (
    <ContentCard className={styles.MachineDisconnect}>
      <DisconnectModal
        machineId={machineId}
        isOpen={isOpenDisconnectModal}
        onDisconnectModalClose={handleDisconnectModalClose}
      />
      <HorizontalContainer space="l" align="center">
        <IconDisconnect size="m" {...defaultIconProps} />
        <VerticalContainer space="2xs">
          <Text size="m" weight="medium">
            Отключить автомат
          </Text>
          <Text size="m" weight="regular" view="secondary">
            Управлять автоматом через телеметрию будет невозможно
          </Text>
        </VerticalContainer>
      </HorizontalContainer>
      <Button
        size="s"
        view="clear"
        className={styles.disconnectButton}
        label="Отключить"
        onClick={handleDisconnectModalOpen}
      />
    </ContentCard>
  );
};

export default MachineDisconnect;

import { FC, useRef, useState } from 'react';
import HorizontalContainer from '../../../../HorizontalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import TableHeaderButton from '../index';
import ContextModal from '../../../../ContextModal';
import styles from './TablePageSettings.module.scss';
import VerticalContainer from '../../../../VerticalContainer';
import { Radio } from '@consta/uikit/Radio';
import { DisplayType, displayTypeValue } from '../../../../../pages/App';
import { IconArrowLeft } from '../../../../../assets/icon/iconArrowLeft';
import { IconArrowRight } from '../../../../../assets/icon/iconArrowRight';
import classNames from 'classnames';
import { TablePageSettingsProps } from '../../../types';

/**
 * Настройка страниц таблицы
 */
const TablePageSettings: FC<TablePageSettingsProps> = ({
  fullLength,
  limit,
  page,
  onNextClick,
  onBackClick,
  onLimitChange,
}) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const [isOpenContextModal, setIsOpenContextModal] = useState(false);
  const pageButtonRef = useRef<HTMLDivElement>(null);

  const firstPageIndex = (page - 1) * limit + 1;
  const lastPageIndex = firstPageIndex + limit <= fullLength ? firstPageIndex + limit : fullLength;
  const pageInfoText = `${firstPageIndex}-${lastPageIndex} из ${fullLength}`;
  const isBackDisabled = firstPageIndex === 1;
  const isNextDisabled = firstPageIndex >= fullLength - limit;

  // Обработчики
  const handlePageInfoTextClick = () => {
    setIsOpenContextModal((prevState) => !prevState);
  };

  return (
    <HorizontalContainer className={styles.isLaptop} space={isLaptop ? '2xs' : 'xs'} align="center">
      <div
        className={classNames(styles.pageButton, isOpenContextModal && styles.isOpen)}
        ref={pageButtonRef}
      >
        <Text className={styles.text} size={isLaptop ? 'm' : 'l'} onClick={handlePageInfoTextClick}>
          {pageInfoText}
        </Text>
      </div>
      <HorizontalContainer space={isLaptop ? '3xs' : '2xs'} align="center">
        <TableHeaderButton
          disabled={isBackDisabled}
          size={isLaptop ? 'm' : 'l'}
          onlyIcon
          tooltipText="Предыдущая страница"
          iconLeft={IconArrowLeft as any}
          onClick={onBackClick}
        />
        <TableHeaderButton
          disabled={isNextDisabled}
          size={isLaptop ? 'm' : 'l'}
          onlyIcon
          tooltipText="Следующая страница"
          iconLeft={IconArrowRight as any}
          onClick={onNextClick}
        />
      </HorizontalContainer>
      <ContextModal
        align="right"
        className={styles.TablePageSettingsContextModal}
        currentRef={pageButtonRef}
        onClickOutside={() => setIsOpenContextModal(false)}
        isOpen={isOpenContextModal}
      >
        <VerticalContainer space="5xl">
          <Text>Отображать по</Text>
          <VerticalContainer space="s">
            <Radio label="10" checked={limit === 10} onClick={() => onLimitChange(10)} />
            <Radio label="20" checked={limit === 20} onClick={() => onLimitChange(20)} />
            <Radio label="50" checked={limit === 50} onClick={() => onLimitChange(50)} />
            <Radio label="100" checked={limit === 100} onClick={() => onLimitChange(100)} />
          </VerticalContainer>
        </VerticalContainer>
      </ContextModal>
    </HorizontalContainer>
  );
};

export default TablePageSettings;

import { FC, useMemo } from 'react';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { IconShakerCup } from '../../../../../assets/icon/iconShakerCup';
import { getCell, getViewStorageItemClassName } from '../../helpers';
import { MachineStoragePageTypeOld } from '../../MachineStorage';
import { IconLock } from '@consta/uikit/IconLock';
import styles from './StorageSchemeCard.module.scss';
import classNames from 'classnames';
import { SeparatedMachineCellUnionType, SeparatedMachineHomeCellUnionType } from '../../types';
import {
  MachineCellGroup,
  MachineCellGroupUnion,
} from '../../../../../types/serverInterface/machineDTO';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import { TextField, TextFieldOnChangeArguments } from '@consta/uikit/TextField';
import { getInputNumberValue } from '../../../../../helpers/ inputHelpers';
import { IconFaucet } from '../../../../../assets/icon/iconFaucet';
import HorizontalContainer from '../../../../HorizontalContainer';
import { IconDropFilled } from '../../../../../assets/icon/iconDropFilled';
import ClassicSlider from '../../../../ClassicSlider';

/**
 * Свойства компонента StorageSchemeCard
 */
type StorageSchemeCardProps = {
  /**
   * Тип страницы
   */
  pageType: MachineStoragePageTypeOld;
  cellGroup: MachineCellGroupUnion;
  /**
   * Ячейка
   */
  storageCell: SeparatedMachineCellUnionType | SeparatedMachineHomeCellUnionType;
  /**
   * Флаг горизонтального расположения
   */
  isHorizontal: boolean;
  /**
   * Обработчик нажатия на ячейку
   */
  onClick?: () => void;
  /**
   * Обработчик события hover на ячейке
   */
  onHover?: () => void;
  /**
   * Обработчик события hoverLeave на ячейке
   */
  onHoverLeave?: () => void;
  /**
   * Обработчик пополнения контейнера
   */
  onValueChange?: (value: number | string | null) => void;
};

/**
 * Ячейка в схеме склада автомата
 */
const StorageSchemeCard: FC<StorageSchemeCardProps> = ({
  pageType,
  storageCell,
  cellGroup,
  isHorizontal,
  onClick = () => {
    null;
  },
  onHover = () => {
    null;
  },
  onHoverLeave = () => {
    null;
  },
  onValueChange = () => {
    null;
  },
}) => {
  const isRefill = pageType === MachineStoragePageTypeOld.REFILL;
  const step =
    cellGroup === MachineCellGroup.CUP || cellGroup === MachineCellGroup.DISPOSABLE
      ? 1
      : cellGroup === MachineCellGroup.WATER
        ? 0.1
        : 100;

  const { info, isActiveStatus, isHoverStatus } = storageCell;

  const { maxVolume, view, isActive } = useMemo(
    () => getCell(storageCell, cellGroup),
    [storageCell, cellGroup],
  );

  // Обработчики
  const handleChange = ({ value }: TextFieldOnChangeArguments) => {
    let formattedValue = value ? parseInt(value, 10) : 0;

    if (isNaN(formattedValue) || formattedValue < 0) {
      formattedValue = 0;
    }

    if (maxVolume && formattedValue > maxVolume) {
      formattedValue = maxVolume;
    }

    onValueChange(formattedValue);
  };

  // render методы
  const renderCellRightSide = () => {
    // if (pageType !== MachineStoragePageTypeOld.REFILL) return null;

    if (pageType !== MachineStoragePageTypeOld.REFILL) {
      switch (cellGroup) {
        case MachineCellGroup.WATER:
        case MachineCellGroup.CUP:
        case MachineCellGroup.DISPOSABLE:
          return (
            <HorizontalContainer space="xs" align="center">
              <Text
                weight="semibold"
                size="m"
                className={classNames(styles.text, styles[(storageCell?.info as any)?.view])}
              >
                {getInputNumberValue(info.volume)}{' '}
                {cellGroup === MachineCellGroup.WATER ? 'л' : 'шт'}
              </Text>
            </HorizontalContainer>
          );
      }
    }

    switch (cellGroup) {
      case MachineCellGroup.WATER:
      case MachineCellGroup.CUP:
      case MachineCellGroup.DISPOSABLE:
        return (
          <HorizontalContainer space="xs" align="center">
            <TextField
              className={classNames(
                cellGroup === MachineCellGroup.CUP ? styles.cupTextField : styles.rightTextField,
              )}
              size="xs"
              type="number"
              min={0}
              incrementButtons={false}
              disabled={(storageCell.info as any).isCount === false}
              value={getInputNumberValue(info.volume)}
              onChange={handleChange}
            />
            <Text size="xs">{cellGroup === MachineCellGroup.WATER ? 'л' : 'шт'}</Text>
          </HorizontalContainer>
        );
    }
  };

  const renderCellInfo = () => {
    switch (cellGroup) {
      case MachineCellGroup.WATER:
        return (
          <div
            className={classNames(
              styles.icon,
              getViewStorageItemClassName(MachineCellGroup.WATER, view),
            )}
          >
            {(storageCell.info as any).isCount ? (
              <IconDropFilled size="s" {...defaultIconProps} />
            ) : (
              <IconFaucet size="s" {...defaultIconProps} />
            )}
          </div>
        );
      case MachineCellGroup.CUP: {
        // const storageInfo = storageCell.info as CupCell;

        return (
          <div
            className={classNames(
              styles.icon,
              getViewStorageItemClassName(MachineCellGroup.CUP, view),
            )}
          >
            <IconShakerCup size="s" {...defaultIconProps} />
            {/*{storageInfo.cupVolume !== null && (*/}
            {/*  <Text*/}
            {/*    weight="semibold"*/}
            {/*    view="secondary"*/}
            {/*    size="xl"*/}
            {/*    className={classNames(*/}
            {/*      styles.text,*/}
            {/*      getViewStorageItemClassName(MachineCellGroup.CUP, view),*/}
            {/*    )}*/}
            {/*  >*/}
            {/*    {`${storageInfo.cupVolume} ${t('machineControl.storage.cupValue.unit')}`}*/}
            {/*  </Text>*/}
            {/*)}*/}
          </div>
        );
      }
      default:
        return isActive === false ? (
          <div
            className={classNames(styles.cellNumberText, getViewStorageItemClassName(null, view))}
          >
            <IconLock size="s" {...defaultIconProps} />
          </div>
        ) : (
          <Text
            weight="semibold"
            view="secondary"
            size={isRefill ? 'l' : 'm'}
            className={classNames(styles.cellNumberText, getViewStorageItemClassName(null, view))}
          >
            {info.cellNumber}
          </Text>
        );
    }
  };

  return (
    <div
      className={classNames(
        styles.storageSchemeCard,
        getViewStorageItemClassName(cellGroup, view),
        isRefill && styles.isRefill,
        isHorizontal && styles.horizontal,
      )}
      onClick={onClick}
      onMouseEnter={onHover}
      onMouseLeave={onHoverLeave}
    >
      {renderCellRightSide()}
      <ClassicSlider
        min={0}
        max={Number(maxVolume)}
        step={step}
        value={info.volume}
        isVertical={!isHorizontal}
        isActiveStatus={isActiveStatus}
        isHoverStatus={isHoverStatus}
        isEdit={isRefill}
        onChange={onValueChange}
      />

      {renderCellInfo()}
    </div>
  );
};

export default StorageSchemeCard;

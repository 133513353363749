import TableColumns from './TableColumns';
import TableRow from './TableRow';
import styles from './TableCardWithBackground.module.scss';
import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { DisplayType, displayTypeValue } from '../../pages/App';
import { ClassicTableProps } from '../ClassicTable/types';
import { SortDirection } from '../../types/enums/sortDirection';
import TableHeader from './TableHeader';

/**
 * Компонент таблицы вида "карточки с фоном"
 */
const TableCardWithBackground = <T extends Record<string, any>, K extends keyof T>(
  props: ClassicTableProps<T, K>,
) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;
  const { withHeader = false, className, rows, columns, onRowClick } = props;

  const [sortOrder, setSortOrder] = useState<Record<K, SortDirection | null>>(
    {} as Record<K, SortDirection | null>,
  );

  const handleSortOrderChange = (newSortOrder: Record<K, SortDirection | null>) => {
    setSortOrder(newSortOrder);
  };

  const tableRows = useMemo(() => {
    return rows.map((data, index) => ({ ...data, index }));
  }, [rows]);

  const sortedRows = useMemo(() => {
    const sorted = [...tableRows];

    const compareFunction = (a: T, b: T) => {
      for (const key in sortOrder) {
        if (sortOrder?.[key] !== null) {
          const order = sortOrder?.[key] === SortDirection.ASC ? 1 : -1;
          const valueA = String(a[key]).toLowerCase();
          const valueB = String(b[key]).toLowerCase();

          if (valueA < valueB) return -order;
          if (valueA > valueB) return order;
        }
      }
      return 0;
    };

    sorted.sort(compareFunction);

    return sorted;
  }, [tableRows, sortOrder]);

  return (
    <div
      className={classNames(styles.TableCardWithBackground, className, isLaptop && styles.LAPTOP)}
    >
      {withHeader && <TableHeader {...props} />}
      <table className={styles.table}>
        <thead className={styles.header}>
          <TableColumns
            columns={columns}
            sortOrder={sortOrder}
            onSortClick={handleSortOrderChange}
          />
        </thead>
        <tbody className={styles.ClassicTableWithBackgroundRows}>
          {sortedRows.map((data) => (
            <TableRow
              key={data.index}
              rowIndex={data.index}
              row={data}
              columns={columns}
              onRowClick={onRowClick}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableCardWithBackground;

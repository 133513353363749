import { baseUrlFront } from '../consts';

const url =
  'https://api.telegram.org/bot7400349875:AAHCONy6D7byy8FWu3GtbXI-U4vJoepWsFE/sendMessage';
const chat_id = '-4234564167';

function jsonToHtml(json: any): string {
  const processValue = (value: any): string => {
    if (typeof value === 'string') {
      return value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    } else if (typeof value === 'object' && value !== null) {
      return jsonToHtml(value);
    } else {
      return String(value);
    }
  };

  const processObject = (obj: any): string => {
    let html = '';
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        html += `<pre><b>${key}</b>: ${processValue(obj[key])}</pre>`;
      }
    }
    return html;
  };

  if (typeof json === 'object' && json !== null) {
    return processObject(json);
  }
  return processValue(json);
}

function transformRequestToPostman(jsonString: string): string {
  const json = JSON.parse(jsonString);

  const method = json.method || 'GET';
  const url = json.url || '';
  const headers = json.config?.headers || {};
  const data = json.data ? JSON.parse(json.data) : {};

  let postmanRequest = `### ${method} Request to Postman\n`;
  postmanRequest += `**URL:** ${url}\n\n`;
  postmanRequest += `**Method:** ${method}\n\n`;
  postmanRequest += `**Headers:**\n`;

  for (const [key, value] of Object.entries(headers)) {
    postmanRequest += `- ${key}: ${value}\n`;
  }

  postmanRequest += `\n**Body:**\n`;
  postmanRequest += `\`\`\`json\n${JSON.stringify(data, null, 2)}\n\`\`\`\n`;

  return postmanRequest;
}

export const sendRequestTelegram = async (text: any) => {
  const formatText = text;

  if (!baseUrlFront || baseUrlFront === 'https://localhost:3000') return null;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Указываем тип контента
      },
      body: JSON.stringify({
        chat_id,
        text: formatText,
      }),
    });

    if (!response.ok) {
      throw new Error(`Ошибка HTTP: ${response.status}`);
    }

    const data = await response.json(); // Обрабатываем полученные данные
    return data;
  } catch (error) {
    console.error('Ошибка при отправке запроса:', error);
    throw new Error('Ошибка при отправке запроса');
  }
};

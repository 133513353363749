import { FC } from 'react';
import DefaultModal from '../../../../../components/DefaultModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import AlertButton from '../../../../../components/AlertButton';
import { useAppDispatch } from '../../../../../app/hooks/store';
import { deleteTelegramNotificationSettingAction } from '../../../../../state/notification/action';
import { Text } from '@consta/uikit/__internal__/src/components/Text';

type DeleteTelegramNotificationSettingProps = {
  selectedIdForDeletion: number;
  onClose: () => void;
};

const DeleteTelegramNotificationSetting: FC<DeleteTelegramNotificationSettingProps> = ({
  selectedIdForDeletion,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  // Обработчики
  const handleDelete = () => {
    dispatch(deleteTelegramNotificationSettingAction(selectedIdForDeletion));
  };

  // render методы
  const renderActions = () => (
    <>
      <Button view="clear" label="Отмена" onClick={onClose} />
      <AlertButton label="Отключить" onClick={handleDelete} />
    </>
  );

  return (
    <DefaultModal
      modalTitle="Отключить уведомления телеграм"
      onClose={onClose}
      renderActions={renderActions}
    >
      <Text>
        Вы действительно хотите отключить уведомления телеграм? Их можно восстановить в любой
        момент.
      </Text>
    </DefaultModal>
  );
};

export default DeleteTelegramNotificationSetting;

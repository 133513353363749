import { FC, useEffect } from 'react';
import ContentCard from '../../../../../components/ContentCard';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../../components/VerticalContainer';
import styles from './MachineHomePageSales.module.scss';
import classNames from 'classnames';
import { IconShakerCup } from '../../../../../assets/icon/iconShakerCup';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import {
  selectMachineSaleInfoLastDate,
  selectMachineSaleInfoQty,
} from '../../../../../state/machineControl/selectors';
import {
  getLastSaleDateByMachineSerialNumberAction,
  getSalesQtyByMachineSerialNumberAction,
} from '../../../../../state/machineControl/actions';
import { transformDate } from '../../../../../helpers/transformDate';

/**
 * Свойства компонента MachineHomePageSales
 */
type MachineHomePageSalesProps = {
  /***
   * Серийный номер автомата
   */
  serialNumber: string;
};

/**
 * Информация о продажах автомата
 */
const MachineHomePageSales: FC<MachineHomePageSalesProps> = ({ serialNumber }) => {
  const dispatch = useAppDispatch();

  const { state: machineSaleInfoLastDate } = useAppSelector(selectMachineSaleInfoLastDate());
  const { state: machineSaleInfoQty } = useAppSelector(selectMachineSaleInfoQty());

  const { day, month, week } = { ...machineSaleInfoQty };
  const { date: dateLastSale } = { ...machineSaleInfoLastDate };

  const [date, time] = (transformDate(dateLastSale || '') || ' ').split(' ');

  useEffect(() => {
    if (serialNumber) {
      dispatch(getLastSaleDateByMachineSerialNumberAction(serialNumber));
      dispatch(getSalesQtyByMachineSerialNumberAction(serialNumber));
    }
  }, [dispatch, serialNumber]);

  return (
    <ContentCard className={styles.MachineHomePageSales}>
      <Text size="l" weight="semibold">
        Продажи
      </Text>
      <HorizontalContainer align="center" space="s">
        <VerticalContainer className={styles.salesCard} space="2xs" align="center">
          <Text size="m" weight="medium" view="secondary">
            Сутки
          </Text>
          <HorizontalContainer justify="center" align="center" space="2xs">
            <Text size="m" view="primary" weight="medium">
              {day}
            </Text>
            <IconShakerCup {...defaultIconProps} size="s" />
          </HorizontalContainer>
        </VerticalContainer>
        <VerticalContainer className={styles.salesCard} space="2xs" align="center">
          <Text size="m" weight="medium" view="secondary">
            Неделя
          </Text>
          <HorizontalContainer justify="center" align="center" space="2xs">
            <Text size="m" view="primary" weight="medium">
              {week}
            </Text>
            <IconShakerCup {...defaultIconProps} size="s" />
          </HorizontalContainer>
        </VerticalContainer>
        <VerticalContainer className={styles.salesCard} space="2xs" align="center">
          <Text size="m" weight="medium" view="secondary">
            Месяц
          </Text>
          <HorizontalContainer justify="center" align="center" space="2xs">
            <Text size="m" view="primary" weight="medium">
              {month}
            </Text>
            <IconShakerCup {...defaultIconProps} size="s" />
          </HorizontalContainer>
        </VerticalContainer>
        <VerticalContainer
          className={classNames(styles.salesCard, styles.lastSaleDate)}
          space="2xs"
          align="center"
        >
          <Text size="m" weight="medium" view="secondary">
            Последняя продажа
          </Text>
          <HorizontalContainer justify="center" align="center" space="2xs">
            <ContentCard className={styles.salesDateCard}>
              <Text size="m" view="primary" weight="medium">
                {time}
              </Text>
            </ContentCard>
            <ContentCard className={styles.salesDateCard}>
              <Text size="m" view="primary" weight="medium">
                {date}
              </Text>
            </ContentCard>
          </HorizontalContainer>
        </VerticalContainer>
      </HorizontalContainer>
    </ContentCard>
  );
};

export default MachineHomePageSales;

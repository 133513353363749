import { FC, useEffect, useState } from 'react';
import VerticalContainer from '../../../components/VerticalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import VendistaSignInForm from './VendistaSignInForm';
import { getOrganizationId } from '../../../helpers/getOrganizationId';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectCheckedVendistaToken } from '../../../state/vendista/selectors';
import { checkVendistaTokenAction } from '../../../state/vendista/action';
import { VendistaTokenStatus } from '../../../types/serverInterface/vendistaDTO';
import { Text } from '@consta/uikit/__internal__/src/components/Text';

/**
 * Страница настройки vendista
 */
const VendistaSettings: FC = () => {
  const dispatch = useAppDispatch();

  const { state: checkedVendistaToken } = useAppSelector(selectCheckedVendistaToken());

  const organizationId = getOrganizationId();

  const [isOpenSignInModal, setIsOpenSignInModal] = useState(false);

  useEffect(() => {
    organizationId && dispatch(checkVendistaTokenAction(organizationId));
  }, [dispatch, organizationId]);

  // Обработчики
  const handleSignInVendistaModalOpen = () => {
    setIsOpenSignInModal(true);
  };

  const handleSignInVendistaModalClose = () => {
    setIsOpenSignInModal(false);
  };

  // render методы
  const renderSignInForm = () =>
    isOpenSignInModal &&
    organizationId && (
      <VendistaSignInForm
        organizationId={organizationId}
        onClose={handleSignInVendistaModalClose}
      />
    );

  const renderContent = () => {
    switch (checkedVendistaToken?.statusToken) {
      case VendistaTokenStatus.NOT_PROVIDED:
        return <Text>Авторизуйтесь в vendista</Text>;
      case VendistaTokenStatus.UNVERIFIED:
        return <Text view="warning">Токен не проверен</Text>;
      case VendistaTokenStatus.WORKING:
        return <Text view="success">Токен работает</Text>;
      case VendistaTokenStatus.NOT_WORKING:
        return <Text view="alert">Токен не работает</Text>;
      default:
        return <Text view="alert">Неизвестная ошибка</Text>;
    }
  };

  return (
    <VerticalContainer>
      {renderSignInForm()}
      {renderContent()}
      {checkedVendistaToken && checkedVendistaToken.login && (
        <Text>{checkedVendistaToken.login}</Text>
      )}
      <div>
        <Button label="Войти в vendista" onClick={handleSignInVendistaModalOpen} />
      </div>
    </VerticalContainer>
  );
};

export default VendistaSettings;

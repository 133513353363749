import { FC } from 'react';
import ContentCard from '../../../../../components/ContentCard';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './MachineHomePageLogs.module.scss';
import { LogMachines } from '../../../../../types/serverInterface/machineDTO';
import MachineHomePageLog from './MachineHomePageLog';

/**
 * Свойства компонента MachineHomePageLogs
 */
type MachineHomePageLogsProps = {
  /**
   * Сообщения автомата
   */
  logs: LogMachines[];
};

/**
 * Сообщения автомата
 */
const MachineHomePageLogs: FC<MachineHomePageLogsProps> = ({ logs }) => {
  // render методы
  const renderLog = (log: LogMachines) => <MachineHomePageLog log={log} />;

  return (
    <ContentCard className={styles.MachineHomePageLogs}>
      <Text size="l" weight="semibold">
        Сообщения от автомата
      </Text>
      {logs?.map((log) => renderLog(log))}
    </ContentCard>
  );
};

export default MachineHomePageLogs;

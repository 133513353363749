import { FC } from 'react';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { useFormik } from 'formik';
import { SignInVendistaTokenReq } from '../../../../types/serverInterface/vendistaDTO';
import { vendistaSignInFormValidationScheme } from './vendistaSignInFormValidationScheme';
import DefaultModal from '../../../../components/DefaultModal';
import { TextField } from '@consta/uikit/TextField';
import { useAppDispatch } from '../../../../app/hooks/store';
import { editVendistaTokenAction } from '../../../../state/vendista/action';
import { getVendistaToken } from './helpers';

const initialFormState: SignInVendistaTokenReq = {
  login: '',
  password: '',
};

type VendistaSignInFormProps = {
  organizationId: number;
  onClose: () => void;
};

/**
 * Форма входа в vendista
 *
 * @param organizationId id организации
 * @param onClose обработчик закрытия формы
 */
const VendistaSignInForm: FC<VendistaSignInFormProps> = ({ organizationId, onClose }) => {
  const dispatch = useAppDispatch();

  const formik = useFormik({
    validationSchema: vendistaSignInFormValidationScheme,
    initialValues: initialFormState,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  // Обработчики
  function handleSubmit({ login, password }: SignInVendistaTokenReq) {
    getVendistaToken(login, password).then((res) => {
      if (res?.token) {
        console.log('Полученный токен:', res.token);

        dispatch(editVendistaTokenAction({ token: res.token, login }, organizationId)).then(() => {
          onClose();
        });
      } else {
        console.log('Не удалось получить токен');
      }
    });
  }

  // render методы
  const renderActions = () => (
    <>
      <Button view="clear" label="Отмена" onClick={onClose} />
      <Button label="Войти" onClick={() => formik.handleSubmit()} />
    </>
  );

  return (
    <DefaultModal modalTitle="Авторизация Vendista" onClose={onClose} renderActions={renderActions}>
      <TextField
        label="login"
        name="login"
        width="full"
        value={formik.values.login}
        status={formik.errors.login ? 'alert' : undefined}
        caption={formik.errors.login}
        onChange={({ e }) => formik.handleChange(e)}
      />
      <TextField
        label="Пароль"
        name="password"
        width="full"
        value={formik.values.password}
        status={formik.errors.password ? 'alert' : undefined}
        caption={formik.errors.password}
        onChange={({ e }) => formik.handleChange(e)}
      />
    </DefaultModal>
  );
};

export default VendistaSignInForm;

import { FC, useEffect } from 'react';
import styles from './MachineHomePage.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import {
  getMachineHomePageInfoAction,
  getMachineLogListAction,
} from '../../../../state/machineControl/actions';
import {
  selectMachineBaseInfo,
  selectMachineHomePageInfo,
  selectMachineLogList,
} from '../../../../state/machineControl/selectors';
import MachineHomePageLogs from './MachineHomePageLogs';
import MachineHomePageHistory from './MachineHomePageHistory';
import MachineHomePageScheme from './MachineHomePageScheme';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../components/VerticalContainer';
import MachineHomePageStatus from './MachineHomePageStatus';
import MachineHomePageSales from './MachineHomePageSales';

/**
 * Свойства компонента MachineHomePage
 */
type MachineHomePageProps = {
  machineId: number;
};

/**
 * Домашняя страница автомата
 */
const MachineHomePage: FC<MachineHomePageProps> = ({ machineId }) => {
  const dispatch = useAppDispatch();

  const { state: machine } = useAppSelector(selectMachineHomePageInfo());
  const { state: machineBaseInfo } = useAppSelector(selectMachineBaseInfo());
  const { state: logMachineList } = useAppSelector(selectMachineLogList());

  useEffect(() => {
    if (machineId) {
      dispatch(getMachineHomePageInfoAction(machineId));
      dispatch(getMachineLogListAction(machineId));
    }
  }, [dispatch, machineId]);

  if (!machine || !machineBaseInfo) return null;

  return (
    <HorizontalContainer space="3xl" className={styles.MachineHomePage}>
      <VerticalContainer space="3xl" className={styles.leftSide}>
        <VerticalContainer space="l">
          <MachineHomePageStatus status={machineBaseInfo.status} />
          <MachineHomePageSales serialNumber={machineBaseInfo.machine.serialNumber} />
        </VerticalContainer>
        <MachineHomePageLogs logs={logMachineList?.logs || []} />
      </VerticalContainer>
      <VerticalContainer space="3xl" className={styles.rightSide}>
        <MachineHomePageScheme storage={machine} />
        <MachineHomePageHistory histories={machine.histories} />
      </VerticalContainer>
    </HorizontalContainer>
  );
};

export default MachineHomePage;

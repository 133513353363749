import { TelegramNotificationSettingDTO } from '../../types/serverInterface/notificationDTO';
import { errorHandler, NotificationType } from '../handlers';
import { MachineControlState } from '../machineControl/slice';
import { createSlice, isRejected } from '@reduxjs/toolkit';
import { getTelegramNotificationSettingsByOrganizationIdThunk } from './thunk';

type StateItemType<T> = {
  state: T extends [] ? T : T | null;
  isLoading: boolean;
  isReject: boolean;
};

export type NotificationState = {
  telegramNotificationSettingsList: StateItemType<TelegramNotificationSettingDTO[]>;
  notifications: NotificationType[];
};

const initialState: NotificationState = {
  telegramNotificationSettingsList: {
    state: [],
    isLoading: false,
    isReject: false,
  },
  notifications: [],
};

/**
 * Добавление уведомления
 *
 * @param state состояние
 * @param notification новое уведомление
 */
const addNotification = (state: NotificationState) => (notification: NotificationType) => {
  const arr = [...state.notifications];
  arr.push(notification);

  state.notifications = arr;
};

export const notificationSlice = createSlice({
  name: 'notificaion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getTelegramNotificationSettingsByOrganizationIdThunk
    builder.addCase(getTelegramNotificationSettingsByOrganizationIdThunk.pending, (state) => {
      state.telegramNotificationSettingsList.isLoading = true;
      state.telegramNotificationSettingsList.isReject = false;
    });

    builder.addCase(getTelegramNotificationSettingsByOrganizationIdThunk.rejected, (state) => {
      state.telegramNotificationSettingsList.isLoading = false;
      state.telegramNotificationSettingsList.isReject = true;
    });

    builder.addCase(
      getTelegramNotificationSettingsByOrganizationIdThunk.fulfilled,
      (state, action) => {
        state.telegramNotificationSettingsList.isLoading = false;
        state.telegramNotificationSettingsList.state = action.payload;
      },
    );

    builder.addMatcher(isRejected(), (state, action) => {
      errorHandler(action)(addNotification(state));
    });
  },
});

export const notificationReducer = notificationSlice.reducer;

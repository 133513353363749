import * as Yup from 'yup';
import { EditMachineVendistaSettingDTO } from '../../../../../types/serverInterface/vendistaDTO';

export const editMachineVendistaSettingValidationScheme: Yup.ObjectSchema<EditMachineVendistaSettingDTO> =
  Yup.object({
    termId: Yup.number()
      .required()
      .min(100000, 'Term ID must be at least 5 characters long')
      .max(99999999, 'Term ID must not exceed 8 characters'),
  });

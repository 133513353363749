import { FC, useEffect, useState } from 'react';
import ContentCard from '../../../../../components/ContentCard';
import styles from './MachineVendistaSetting.module.scss';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import {
  editMachineVendistaSettingAction,
  getMachineVendistaSettingAction,
} from '../../../../../state/vendista/action';
import { selectMachineVendistaSetting } from '../../../../../state/vendista/selectors';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import DefaultModal from '../../../../../components/DefaultModal';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { useFormik } from 'formik';
import { editMachineVendistaSettingValidationScheme } from './editMachineVendistaSettingValidationScheme';
import { IconCalculator } from '../../../../../assets/icon/iconCalculator';

type MachineVendistaSettingProps = {
  machineId: number;
};

const MachineVendistaSetting: FC<MachineVendistaSettingProps> = ({ machineId }) => {
  const dispatch = useAppDispatch();

  const { state: machineVendistaSettingInfo } = useAppSelector(selectMachineVendistaSetting());

  const [isEditOpen, setIsEditOpen] = useState(false);

  useEffect(() => {
    dispatch(getMachineVendistaSettingAction(machineId));
  }, [dispatch, machineId]);

  const formik = useFormik({
    validationSchema: editMachineVendistaSettingValidationScheme,
    initialValues: { termId: null },
    validateOnChange: false,
    onSubmit: (data) => {
      dispatch(editMachineVendistaSettingAction(machineId, data));
    },
  });

  // Обработчики
  const handleEditOpenClick = () => {
    setIsEditOpen(true);
  };

  const handleEditClose = () => {
    setIsEditOpen(false);
  };

  // render методы
  const renderTermId = () =>
    machineVendistaSettingInfo?.termId ? (
      <Text size="m" weight="regular" view="secondary">
        Номер терминала {machineVendistaSettingInfo?.termId}
      </Text>
    ) : (
      <Text size="m" weight="regular" view="secondary">
        Интеграция не настроена
      </Text>
    );

  const renderModalAction = () => (
    <>
      <Button size="l" label="Отмена" view="clear" onClick={handleEditClose} />
      <Button
        size="l"
        label="Сохранить"
        onClick={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      />
    </>
  );

  const renderEditModal = () => (
    <DefaultModal
      className={styles.editModal}
      isOpen={isEditOpen}
      modalTitle="Терминал Vendista"
      onClose={handleEditClose}
      renderActions={renderModalAction}
    >
      <TextField
        size="l"
        label="Номер терминала"
        name="termId"
        type="number"
        width="full"
        value={formik.values.termId}
        status={formik.errors.termId ? 'alert' : undefined}
        caption={formik.errors.termId}
        onChange={({ e }) => formik.handleChange(e)}
      />
    </DefaultModal>
  );

  return (
    <ContentCard className={styles.MachineVendistaSetting}>
      {renderEditModal()}
      <HorizontalContainer space="l" align="center">
        <IconCalculator size="m" {...defaultIconProps} />
        <VerticalContainer space="2xs">
          <Text size="m" weight="medium">
            Терминал vendista
          </Text>
          {renderTermId()}
        </VerticalContainer>
      </HorizontalContainer>
      <HorizontalContainer space="l" align="center">
        <div className={styles.errorStatus}></div>
        <IconArrowRight {...defaultIconProps} size="m" onClick={handleEditOpenClick} />
      </HorizontalContainer>
    </ContentCard>
  );
};

export default MachineVendistaSetting;

import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { getTelegramNotificationSettingsByOrganizationIdAction } from '../../../../state/notification/action';
import { getOrganizationId } from '../../../../helpers/getOrganizationId';
import { selectTelegramNotificationSettingsList } from '../../../../state/notification/selectors';
import { Loader } from '@consta/uikit/Loader';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import VerticalContainer from '../../../../components/VerticalContainer';
import DefaultInfo from '../../../../components/DefaultInfo';
import DefaultInfoItem from '../../../../components/DefaultInfoItem';
import CreateTelegramNotificationSetting from './CreateTelegramNotificationSetting';
import { TelegramNotificationSettingDTO } from '../../../../types/serverInterface/notificationDTO';
import EditTelegramNotificationSetting from './EditTelegramNotificationSetting';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import AlertButton from '../../../../components/AlertButton';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import DeleteTelegramNotificationSetting from './DeleteTelegramNotificationSetting';

const TelegramNotificationSettings: FC = () => {
  const dispatch = useAppDispatch();

  const organizationId = getOrganizationId();

  const { state: telegramNotificationList, isLoading } = useAppSelector(
    selectTelegramNotificationSettingsList(),
  );

  const noNotificationsCreated = !telegramNotificationList?.length;

  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [editingSetting, setIsEditingSetting] = useState<TelegramNotificationSettingDTO | null>(
    null,
  );
  const [selectedIdForDeletion, setSelectedIdForDeletion] = useState<number | null>(null);

  useEffect(() => {
    organizationId &&
      dispatch(getTelegramNotificationSettingsByOrganizationIdAction(organizationId));
  }, [organizationId, dispatch]);

  // Обработчики
  const handleCreateClick = () => {
    setIsOpenCreateModal(true);
  };

  const handleCreateClose = () => {
    setIsOpenCreateModal(false);
  };

  const handleEditClick = () => {
    !noNotificationsCreated && setIsEditingSetting(telegramNotificationList[0]);
  };

  const handleEditClose = () => {
    setIsEditingSetting(null);
  };

  const handleDeleteClick = () => {
    !noNotificationsCreated && setSelectedIdForDeletion(telegramNotificationList[0].id);
  };

  const handleDeleteCancel = () => {
    setSelectedIdForDeletion(null);
  };

  // render методы
  const renderAction = () =>
    noNotificationsCreated ? (
      <HorizontalContainer>
        <Button label="Настроить telegram уведомления" onClick={handleCreateClick} />
      </HorizontalContainer>
    ) : (
      <HorizontalContainer>
        <Button label="Настроить telegram уведомления" onClick={handleEditClick} />
        <AlertButton label="Отключить уведомления" onClick={handleDeleteClick} />
      </HorizontalContainer>
    );

  const renderCreateForm = () =>
    isOpenCreateModal && <CreateTelegramNotificationSetting onClose={handleCreateClose} />;

  const renderEditForm = () =>
    editingSetting && (
      <EditTelegramNotificationSetting editedSetting={editingSetting} onClose={handleEditClose} />
    );

  const renderDeleteModal = () =>
    selectedIdForDeletion && (
      <DeleteTelegramNotificationSetting
        selectedIdForDeletion={selectedIdForDeletion}
        onClose={handleDeleteCancel}
      />
    );

  if (!organizationId) return null;

  if (isLoading) return <Loader />;

  return (
    <VerticalContainer>
      {renderCreateForm()}
      {renderEditForm()}
      {renderDeleteModal()}
      {renderAction()}
      {telegramNotificationList?.map((notification, index) => (
        <DefaultInfo key={index}>
          <Text>Телеграм бот</Text>
          <DefaultInfoItem
            item={{
              name: 'chatId',
              value: notification.chatId,
            }}
          />
          <DefaultInfoItem
            item={{
              name: 'botToken',
              value: notification.botToken,
            }}
          />
        </DefaultInfo>
      ))}
    </VerticalContainer>
  );
};

export default TelegramNotificationSettings;

import { FC } from 'react';
import { MachineStatus, StatusColor } from '../../../../../types/serverInterface/machineDTO';
import ContentCard from '../../../../../components/ContentCard';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './MachineHomePageStatus.module.scss';
import HorizontalContainer from '../../../../../components/HorizontalContainer';

/**
 * Свойства компонента MachineHomePageStatus
 */
type MachineHomePageStatusProps = {
  /**
   * Статус автомата
   */
  status: MachineStatus;
};

/**
 * Статус автомата
 */
const MachineHomePageStatus: FC<MachineHomePageStatusProps> = ({ status }) => {
  const { text, date: dateStr, color } = status;
  const [date, time] = (dateStr || '').split(' ');

  // Вспомогательные методы
  const getTextView = () => {
    switch (color) {
      case StatusColor.SUCCESS:
        return 'success';
      case StatusColor.WARNING:
        return 'warning';
      case StatusColor.ERROR:
        return 'alert';
      default:
        return 'system';
    }
  };

  return (
    <ContentCard className={styles.MachineHomePageStatus}>
      <Text size="l" weight="semibold">
        Статус
      </Text>
      <HorizontalContainer className={styles.statusCard} align="center">
        <Text size="m" weight="semibold" view={getTextView()}>
          {text}
        </Text>
        <VerticalContainer align="end" space={0}>
          <Text size="s" view="secondary">
            {date}
          </Text>
          <Text size="s" view="secondary">
            {time}
          </Text>
        </VerticalContainer>
      </HorizontalContainer>
    </ContentCard>
  );
};

export default MachineHomePageStatus;

import { FC } from 'react';
import {
  MachineStorageProductCellType,
  SeparatedMachineStorageType,
} from '../../../../../../components/Machine/MachineStorage/types';
import { ProductGroup, ProductView } from '../../../../../../types/serverInterface/storageDTO';
import styles from './MachineRefillTable.module.scss';
import ContentCard from '../../../../../../components/ContentCard';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../app/hooks/store';
import { selectCellCategoryUnit } from '../../../../../../state/productBase/selectors';
import {
  MachineCellGroupUnion,
  MachineStorageInfoProductCellFormatted,
} from '../../../../../../types/serverInterface/machineDTO';
import { TextField } from '@consta/uikit/TextField';
import HorizontalContainer from '../../../../../../components/HorizontalContainer';
import {
  getInputNumberValue,
  getLimitedInputNumberOrNull,
} from '../../../../../../helpers/ inputHelpers';

/**
 * Свойства компонента MachineFillingInfoTable
 */
type MachineFillingInfoTableProps = {
  /**
   * Склада автомата
   */
  storage: SeparatedMachineStorageType;
  /**
   * Сортированный список групп продуктов склада автомата
   */
  sortedGroup: ProductGroup[];
  /**
   * Обработчик hover на ячейку склада
   *
   * @param group группа ячеек склада
   * @param index индекс внутри группы
   */
  onHover: (group: MachineCellGroupUnion) => (index: number) => void;
  /**
   * Обработчики hover leave на ячейку склада
   *
   * @param group группа ячеек склада
   * @param index индекс внутри группы
   */
  onHoverLeave: (group: MachineCellGroupUnion) => (index: number) => void;
  onInputChange: (
    group: MachineCellGroupUnion,
  ) => (
    index: number,
  ) => (key: keyof MachineStorageInfoProductCellFormatted) => (value: string | null) => void;
};

/**
 * Таблица склада с информацией о наполнении автомата
 */
const MachineRefillTable: FC<MachineFillingInfoTableProps> = ({
  storage,
  sortedGroup,
  onHover,
  onHoverLeave,
  onInputChange,
}) => {
  const cellCategoryMap = useAppSelector(selectCellCategoryUnit());

  const { t } = useTranslation();

  // Обработчики
  const handleValueChange =
    (maxVolume: number) =>
    (group: MachineCellGroupUnion) =>
    (index: number) =>
    ({ value }: { value: string | null }) => {
      onInputChange(group)(index)('volume')(getLimitedInputNumberOrNull(value, 0, maxVolume));
    };

  // render методы
  const renderHeader = () => (
    <div className={styles.head}>
      <div className={styles.row}>
        <div className={classNames(styles.cell, styles.cellNumberCellHeader)}>
          <Text size="s" weight="semibold" view="secondary">
            №
          </Text>
        </div>
        <div className={classNames(styles.cell, styles.brandCell)}>
          <Text size="s" weight="semibold" view="secondary">
            Бренд
          </Text>
        </div>
        <div className={classNames(styles.cell, styles.ingredientLineCell)}>
          <Text size="s" weight="semibold" view="secondary">
            Линейка
          </Text>
        </div>
        <div className={classNames(styles.cell, styles.ingredientCell)}>
          <Text size="s" weight="semibold" view="secondary">
            Вкус
          </Text>
        </div>
        <div className={classNames(styles.cell, styles.volumeCell)} />
      </div>
    </div>
  );

  const renderRow = (
    { info, isHoverStatus }: MachineStorageProductCellType,
    index: number,
    group: ProductGroup,
  ) => (
    <div
      className={classNames(styles.row, styles[info.view], isHoverStatus && styles.hover)}
      onMouseEnter={() => onHover(info.group)(index)}
      onMouseLeave={() => onHoverLeave(info.group)(index)}
    >
      <div className={classNames(styles.cell, styles.cellNumberCell)}>
        <Text className={styles.text} size="s" weight="regular">
          {info.cellNumber}
        </Text>
      </div>
      <div className={classNames(styles.cell, styles.brandCell)}>
        <Text className={styles.text} size="s" weight="regular">
          {info.brandName}
        </Text>
      </div>
      <div className={classNames(styles.cell, styles.ingredientLineCell)}>
        <Text className={styles.text} size="s" weight="regular">
          {info.ingredientLineName}
        </Text>
      </div>
      <div className={classNames(styles.cell, styles.ingredientCell)}>
        <Text className={styles.text} size="s" weight="regular">
          {info.ingredientName}
        </Text>
      </div>
      <div className={classNames(styles.cell, styles.volumeCell)}>
        <HorizontalContainer space={0}>
          <TextField
            className={classNames(
              info.view === ProductView.PRIMARY ? styles.primaryTextField : styles.defaultTextField,
            )}
            size="xs"
            min={0}
            value={getInputNumberValue(info.volume)}
            type="number"
            incrementButtons={false}
            onChange={handleValueChange(info.maxVolume)(group)(index)}
            onDrop={(e) => {
              e.preventDefault();
            }}
          />
          <Text className={styles.unitText} size="xs" view="secondary">
            {cellCategoryMap[info.cellCategoryId]
              ? t(`productBase.units.${cellCategoryMap[info.cellCategoryId]}`)
              : ''}
          </Text>
        </HorizontalContainer>
      </div>
    </div>
  );

  const renderRows = () => (
    <div className={styles.body}>
      {sortedGroup.map((group) =>
        storage.cells[group].map((cell, index) => renderRow(cell, index, group)),
      )}
    </div>
  );

  return (
    <ContentCard className={styles.MachineFillingInfoTable}>
      <div className={styles.table}>
        {renderHeader()}
        {renderRows()}
      </div>
    </ContentCard>
  );
};

export default MachineRefillTable;
